export function whenOpen(name, callback, {ones =  false} = {}){
	document.addEventListener('popupOpen', openHandler)
	function openHandler({detail: e}){
		handlers.open({e, name, callback, ones, openHandler});
	}
}
export function whenClose(name, callback, {ones = false} = {}){
	document.addEventListener('popupClose', closeHandler)
	if(ones){
		document.removeEventListener('popupClose', closeHandler)
	}
	function closeHandler({detail: e}){
		handlers.close({e, name, callback, ones, closeHandler});
	}
}
const handlers = {
	open: function({e, name, callback, ones, openHandler}){
		if(e.name === name){
			if(ones){
				document.removeEventListener('popupOpen', openHandler)
			}
			callback(e);
		}
	},
	close: function({e, name, callback, ones, closeHandler}){
		if(e.name === name){
			if(ones){
				document.removeEventListener('popupClose', closeHandler)
			}
			callback(e);
		}
	},
}
