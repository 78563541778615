window.addEventListener('resize', ()=>{
	Object.keys(list).forEach((size)=>{
		if(size === 'array'){
			list[size].forEach((item)=>{
				if(window.innerWidth > item.size[0] && window.innerWidth <= item.size[1]){
					item.callback()
				}
			})
		}else if(window.innerWidth <= size){
			list[size]()
		}
	})
})
const list = {
	array: []
}
export function onRezise(callback, size = [0, Infinity]){
		if(typeof(size) === 'object'){
			list['array'].push({
				size,
				callback
			})
		}else if(typeof(size) === 'number'){
			list[size] = callback
		}
}