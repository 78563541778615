import { load }  from "./events/load.js";
import { clickOn } from "./events/click.js";
import { intersect }  from "./events/intersection.js";

load(()=>{
	const video = document.querySelector('#video video')
	clickOn('#video .viewButton, #video .viewButton *', (e)=>{
		video.play()
		e.target.closest('.viewButton').classList.add('active')
	})
	intersect('#video', (e)=>{
		if(!e.isIntersecting){
			video.pause()
			document.querySelector('#video .viewButton').classList.remove('active')
		}
	})
})