import gsap from "gsap";
gsap.registerPlugin(ScrollTrigger);

const s = {
  logo: "#logoScreen .container > *",
  hero: "#hero",
  about: "#about",
  services: "#services",
  results: "#results",
  text: "#block-text",
	blog: "#blog",
	gallery: "#gallery",
	cta: "#cta",
};
gsap.from(s.logo, {
  opacity: 0,
  duration: 1,
  ease: "back.out(1.7)",
  stagger: 0.1,
	y: 150,
  onComplete: () => {
    gsap.set("#logoScreen .container > *", {
      clearProps: "all",
    });
    gsap.to(s.logo, {
      scrollTrigger: {
        trigger: "#logoScreen",
        start: "top top",
        end: "bottom -10%",
        scrub: 1,
      },
      y: 100,
      ease: "linear",
      delay: 0.3,
    });
  },
});

// Hero
gsap.from(`${s.hero} .container .hero__content > *`, {
  scrollTrigger: {
    trigger: "section#hero",
    start: "top 60%",
  },
  opacity: 0,
  y: 150,
  duration: 1,
  ease: "back.out(1.7)",
  stagger: 0.1,
});
gsap.from(`${s.hero} .container .hero__content .content > *`, {
  scrollTrigger: {
    trigger: "section#hero",
    start: "top 60%",
  },
  x: 150,
  y: 50,
  duration: 1,
  ease: "back.out(1.7)",
  stagger: 0.1,
  onComplete: () => {
    gsap.fromTo(
      `#home ${s.hero} .image-thumb`,
      {
				backgroundSize: "100%",
				backgroundPositionY: "50%",
      },
      {
        scrollTrigger: {
          trigger: "section#hero",
          start: "top 20%",
          end: "bottom -10%",
          scrub: 1,
        },
				backgroundSize: "120%",
				backgroundPositionY: "0%",
        ease: "linear",
      }
    );
    gsap.fromTo(
      `${s.hero} .popup-burger-menu`,
      {
        x: 0,
        y: 0,
      },
      {
        scrollTrigger: {
          trigger: "section#hero",
          start: "top 0%",
          end: "+=70",
          scrub: 1,
        },
        x: 100,
        opacity: 0,
        ease: "linear",
      }
    );
  },
});
gsap.from(`${s.hero} .logo`, {
  scrollTrigger: {
    trigger: "section#hero",
    start: "top 60%",
  },
  opacity: 0,
  x: -150,
  y: 50,
  duration: 1,
  ease: "back.out(1.7)",
  onComplete: () => {
    gsap.set(`section#hero .logo`, { clearProps: "all" });
  },
});
gsap.from(`${s.hero} .popup-burger-menu`, {
  scrollTrigger: {
    trigger: "section#hero",
    start: "top 60%",
  },
  opacity: 0,
  x: 150,
  y: 50,
  duration: 1,
  ease: "back.out(1.7)",
  onComplete: () => {
    gsap.set(`section#hero .popup-burger-menu`, { clearProps: "all" });
  },
});

// About

gsap.fromTo(
  `${s.about} .container .heading .title`,
  {
    opacity: 0,
    y: 30,
    x: -10,
  },
  {
    scrollTrigger: {
      trigger: s.about,
      start: "top 80%",
      end: "top 40%",
      scrub: 1,
    },
    opacity: 1,
    y: 0,
    x: 0,
    ease: "linear",
  }
);
gsap.fromTo(
  `${s.about} .container .heading [class^="popup-"]`,
  {
    opacity: 0,
    y: 30,
    x: 10,
  },
  {
    scrollTrigger: {
      trigger: s.about,
      start: "top 80%",
      end: "top 40%",
      scrub: 1,
    },
    opacity: 1,
    y: 0,
    x: 0,
    ease: "linear",
  }
);
gsap.fromTo(
  `${s.about} .container .content .image-thumb`,
  {
    backgroundPositionY: "50%",
		backgroundSize: "100%",
  },
  {
    scrollTrigger: {
      trigger: s.about,
      start: "bottom 80%",
      end: "bottom -10%",
      scrub: 1,
    },
		backgroundPositionY: "0%",
		backgroundSize: "120%",
    ease: "linear",
  }
);
gsap.fromTo(
  `${s.about} .container .content .image-thumb`,
  {
    opacity: 0,
		y: -60,
    x: 15,
  },
  {
    scrollTrigger: {
      trigger: s.about,
      start: "top 80%",
      end: "top 40%",
      scrub: 1,
    },
    opacity: 1,
		y: 0,
    x: 0,
    ease: "linear",
  }
);
gsap.fromTo(
  `${s.about} .container .content .text > *`,
  {
    opacity: 0,
    y: 60,
    x: -15,
    skewY: -2,
  },
  {
    scrollTrigger: {
      trigger: s.about,
      start: "top 80%",
      end: "top	 40%",
      scrub: 1,
    },
    opacity: 1,
    y: 0,
    x: 0,
    skewY: 0,
    ease: "linear",
    stagger: 0.2,
  }
);

// Services

gsap.fromTo(
  `${s.services} .container > * > *`,
  {
    y: -40,
    opacity: 0,
  },
  {
    scrollTrigger: {
      trigger: s.services,
      start: "top 80%",
      end: "top 40%",
      scrub: 1,
    },
    y: 0,
    opacity: 1,
    ease: "linear",
    stagger: 0.1,
  }
);
gsap.fromTo(
  `${s.services} .services-swiper`,
  {
    y: 0,
  },
  {
    y: 30,
    scrollTrigger: {
      trigger: s.services,
      start: "bottom 70%`",
      end: "bottom -10%",
      scrub: 1,
    },
    ease: "linear",
    stagger: 0.1,
  }
);

// Results
gsap.fromTo(
  `${s.results} .container > * > * `,
  {
    opacity: 0,
    x: -10,
    y: -60,
  },
  {
    scrollTrigger: {
      trigger: s.results,
      start: "top 80%",
      end: "top 40%",
      scrub: 1,
    },
    x: 0,
    y: 0,
    opacity: 1,
    ease: "linear",
    stagger: 0.05,
  }
);

// gsap.fromTo(
//   `${s.results} .container .results-list > * `,
// 	{
// 		x: 0,
// 		y: 0,
// 		opacity: 1,
// 	},
//   {
//     scrollTrigger: {
//       trigger: s.results,
//       start: "bottom 80%",
//       end: "bottom -20%",
//       scrub: 1,
//     },
//     x: 100,
//     y: 60,
// 		opacity: 0,
//     ease: "linear",
//     stagger: 0.07,
//   }
// );

// Block text
gsap.fromTo(
  `${s.text} .container`,
  {
    y: -60,
    opacity: 0,
    clipPath: "circle(0% at 50% 0%)",
  },
  {
    scrollTrigger: {
      trigger: s.text,
      start: "top 80%",
      end: "top 40%",
      scrub: 1,
    },
    y: 0,
    opacity: 1,
    clipPath: "circle(100% at 50% 50%)",
    ease: "linear",
  }
);

// Blog

gsap.fromTo(
	`${s.blog} .container > * > *`,
	{
		opacity: 0,
		y: 30,
	},
	{
		scrollTrigger: {
			trigger: s.blog,
			start: "top 70%",
			end: "top 30%",
			scrub: 1,
		},
		opacity: 1,
		y: 0,
		ease: "linear",
		stagger: 0.07,
	}
);


// Gallery

gsap.fromTo(
	`${s.gallery} .container > * > *`,
	{
		opacity: 0,
		y: 30,
	},
	{
		scrollTrigger: {
			trigger: s.gallery,
			start: "top 70%",
			end: "top 30%",
			scrub: 1,
		},
		opacity: 1,
		y: 0,
		ease: "linear",
		stagger: 0.07,
	}
)

// CTA

gsap.fromTo(
	`${s.cta} > * > *`,
	{
		opacity: 0,
		y: 30,
	},
	{
		scrollTrigger: {
			trigger: s.cta,
			start: "top 80%",
			end: "top 40%",
			scrub: 1,
		},
		opacity: 1,
		y: 0,
		ease: "linear",
		stagger: 0.07,
	}
)