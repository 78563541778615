import { clickOn } from "./events/click";
import { getProp } from "./accordeonFunctions";

const handlers = {
	open: null, 
	close: null,
	clickOutside: null
}

clickOn("[class*='popup-'], [class*='popup-'] > *", (e) => {
  const popup_name = Array.from(e.target.closest("[class*='popup-']").classList)
    .filter((className) => className.startsWith("popup-"))[0]
    .split("popup-")[1];
  let backdropElement = document.querySelector(`.backdrop.${popup_name}`),
    dataForEvent = {
      popup: backdropElement,
      button: e.target.closest("[class*='popup-']"),
      name: popup_name,
      selector: `.${popup_name}.backdrop`,
    };

  function closeHandler(event) {
    closePopup({ backdropElement, dataForEvent, clickOutsideHandler, closeHandler });
  }
	handlers.close = closeHandler;
	function openHandler(event) {
		openPopup({ backdropElement, dataForEvent, closeHandler, clickOutsideHandler });
	}
	handlers.open = openHandler;
	function clickOutsideHandler(event) {
		clickOutsideFunction(event, { backdropElement, dataForEvent });
	}
	handlers.clickOutside = clickOutsideHandler;
	

  if (backdropElement) {
		openHandler(e);
  }
});

export function openPopup({
  backdropElement = document.querySelector(".backdrop"),
  dataForEvent = {},
	closeHandler,
	clickOutsideHandler
}) {
  backdropElement.classList.add("show");
  document.dispatchEvent(
    new CustomEvent("popupOpen", { detail: dataForEvent })
  );
  backdropElement
    .querySelector(".close-button")
    ?.removeEventListener("click", closeHandler);
  window.removeEventListener("click", clickOutsideHandler);
  setTimeout(() => {
    backdropElement
      .querySelector(".close-button")
      ?.addEventListener("click", closeHandler);
    window.addEventListener("click", clickOutsideHandler);
  }, parseInt(getProp(backdropElement, "--_show-speed", 500)));
}

export function closePopup({
  backdropElement = document.querySelector(".backdrop.show"),
  dataForEvent = {},
	closeHandler,
	clickOutsideHandler
}) {
  document.dispatchEvent(
    new CustomEvent("popupClose", { detail: dataForEvent })
  );
  backdropElement?.classList.remove("show");
  backdropElement?.classList.add("close");
  backdropElement
    ?.querySelector(".close-button")
    ?.removeEventListener("click", closeHandler);
  window.removeEventListener("click", clickOutsideHandler);
  document.documentElement.classList.add("interaction-disabled");
  setTimeout(() => {
    backdropElement?.classList.remove("close");
    document.documentElement.classList.remove("interaction-disabled");
  }, parseInt(getComputedStyle(backdropElement)?.getPropertyValue("--_show-speed")) || 500);
}

export function clickOutsideFunction(
  event,
  {
    backdropElement = document.querySelector(".backdrop.show"),
    dataForEvent = {},
  }
) {
  if (event.target == backdropElement) {
    closePopup({ backdropElement, dataForEvent });
  }
}

import { whenOpen } from "./events/popup";

function getHandlers(){
	return handlers;
}

whenOpen('burger-menu', (event) => {
	clickOn('.backdrop.burger-menu .menu-item a', ()=>{
		getHandlers().close();
	})
}, {
	ones: true
});
