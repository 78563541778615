export function onTransitionEnd(
  element = null,
  { name, callback, ones = false }
) {
  if (element === null || typeof element === "undefined") {
    return;
  }
  element.addEventListener("transitionend", function onTransitionEnd(event) {
		if(ones){
			element.removeEventListener("transitionend", onTransitionEnd);
		}

    (name && name === event.propertyName && callback(event, element)) ||
      (!name && callback(event, element));
  });
}
export function onTransitionStart(
  element = null,
  { name, callback, ones = false }
) {
  if (element === null || typeof element === "undefined") {
    return;
  }
  element.addEventListener("transitionstart", function onTransitionEnd(event) {
		if(ones){
			element.removeEventListener("transitionstart", onTransitionEnd);
		}

    (name && name === event.propertyName && callback(event, element)) ||
      (!name && callback(event, element));
  });
}