function get_buttons(name, data_name) {
  return document.querySelectorAll(
    `.accordeon-${name}[data-name="${data_name}"]`
  );
}
function get_accordeon(name, data_name) {
  return document.querySelector(`.accordeon.${name}[data-name="${data_name}"]`);
}
function openAccordeon(name, data_name) {
  const a = {
    a: get_accordeon(name, data_name),
    b: get_buttons(name, data_name),
  };
  a.a.classList.add("open");
  [...a.b].forEach((button) => {
    button.classList.add("active");
  });
}
function closeAccordeons(name, element = document){
	element.querySelectorAll(`.accordeon.${name}`).forEach(accordeon => {
		accordeon.classList.remove("open", "autoHeight");
		const data_name = accordeon.dataset.name;
		document.querySelectorAll(`.accordeon-${name}[data-name="${data_name}"]`).forEach(button => {
			button.classList.remove("active");
		})
	})
	element.querySelectorAll(`.accordeon-${name}`).forEach(button => {
		button.classList.remove("active");
	})
}
function toggleAccordeon(name, data_name) {
	const a = {
		a: get_accordeon(name, data_name),
		b: get_buttons(name, data_name),
	};
	a.a.classList.toggle("open");
	[...a.b].forEach((button) => {
		button.classList.toggle("active");
	});
}
function setProp(element, property, value) {
	element.style.setProperty(property, value);
}
function getProp(element, property, default_value = null) {
	return getComputedStyle(element).getPropertyValue(property) || default_value;
}
export { openAccordeon, get_buttons, get_accordeon, closeAccordeons, toggleAccordeon, setProp, getProp };