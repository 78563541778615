import gsap from "gsap";
gsap.registerPlugin(ScrollTrigger);

const s = {
  d: "#doctors",
  v: "#video",
  m: "#map",
  f: "footer.footer",
	h: '#single-service #hero',
	p: "#price",
};

const animations = {
  easeFropBottom: function (
    element,
    section,
    {
      y = 50,
      opacity = 0,
      start = "top 80%",
      end = "top 40%",
      stagger = 0.07,
      ease = "back.out(1.7)",
			markers = false,
    } = {}
  ) {
    gsap.fromTo(
      element,
      {
        opacity,
        y,
      },
      {
        opacity: 1,
        y: 0,
        ease,
        stagger,
        scrollTrigger: {
					markers,
          trigger: section,
          start,
          end,
          scrub: 1,
        },
      }
    );
  },
	easeIn: function(
		element,
		section,
		{
			x = 0,
			y = 0,
			start = "top 80%",
			end = "top 40%",
			stagger = 0.07,
			onComplete = () => {},
		} = {}
	){
		gsap.fromTo(
			element,
			{
				opacity: 0,
				y,
				x,
			},
			{
				opacity: 1,
				y: 0,
				x: 0,
				stagger,
				scrollTrigger: {
					trigger: section,
					start,
					end,
				},
				onComplete
			}
		)
	},
	easeOut: function(
		element,
		section,
		{
			x = 0,
			y = 0,
			start = "top -20%",
			end = " top	-40%",
			stagger = 0.07,
			markers = false,
		} = {}
	){
		gsap.fromTo(
			element,
			{
				opacity: 1,
				y,
				x,
			},
			{
				opacity: 1,
				y: 0,
				x: 0,
				stagger,
				scrollTrigger: {
					trigger: section,
					scrub: 1,
					markers,
					start,
					end,
				}
			}
		)
	}
};
animations.easeFropBottom(
  `${s.d} .doctor-item.swiper-slide, ${s.d} .heading > *`,
  s.d,
	{
		y: 100,
		end: "top 10%",
	}
);

animations.easeFropBottom(
	`${s.v} .video-window`,
	 s.v,
	 {
		 start: "top 70%",
		 end: "top 30%",
	 }
);

animations.easeFropBottom(
	`${s.m} .map-block > *, ${s.m}`,
	s.m,
	{
		start: "top 70%",
		end: "top 30%",
	}
);
animations.easeFropBottom(
	`${s.f} .content > *, ${s.f} .content > * > *`,
	s.f,
	{
		start: "top 90%",
		end: "bottom 100%",
	}
);

animations.easeIn(
	`${s.h} > *, ${s.h} .content > * > *`,
	s.h,
	{
		y: 50
	}
);
animations.easeIn(
	`${s.p} .content > * > *`,
	s.p,
	{
		y: 50,
	}
)