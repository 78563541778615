import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import { clickOn } from "./events/click";

const services = new Swiper(".services-swiper", {
  slidesPerView: 2,
	breakpoints: {
		1200:{
			slidesPerView: 3,
		},
	},
	navigation: {
		nextEl: "#services .swiper-control.next",
		prevEl: "#services .swiper-control.prev",
	},
  pagination: {
    el: "#services .counter"
  },

  on: {
    init: updSwiperNumericPagination,
    slideChange: updSwiperNumericPagination,
		resize: updSwiperNumericPagination,
  }
});
const gallery = new Swiper("#gallery .swiper", {
	slidesPerView: 1,
	speed: 700,
	spaceBetween: 34.08,
	navigation: {
		nextEl: "#gallery .mobile-controls .swiper-control.next",
		prevEl: "#gallery .mobile-controls .swiper-control.prev",
	},
	pagination: {
		el: "#gallery .mobile-controls .counter"
	},
	breakpoints: {
		992: {
			slidesPerView: 2,
			navigation: {
				nextEl: "#gallery .swiper-control.next",
				prevEl: "#gallery .swiper-control.prev",
			},
			pagination: {
				el: "#gallery .heading .counter"
			},

		}
	},
	on: {
		init: updSwiperNumericPagination,
		slideChange: updSwiperNumericPagination,
		resize: updSwiperNumericPagination,
		breakpoint: function(){
			this.navigation.destroy();
			this.navigation.init();
			this.navigation.update();
			this.pagination.destroy();
			this.pagination.init();
			this.pagination.update();
		}
	}
});

const doctors = new Swiper("#doctors .doctors-list.swiper", {
	slidesPerView: 1, 
	spaceBetween: 30,
	breakpoints: {
		768:{
			slidesPerView: 2,
		},
		1200:{
			slidesPerView: 4,
		},
	},
	navigation: {
		nextEl: "#doctors .swiper-control.next",
		prevEl: "#doctors .swiper-control.prev",
	},
	pagination: {
		el: "#doctors .counter"
	},
	on: {
		init: updSwiperNumericPagination,
		slideChange: updSwiperNumericPagination,
		resize: updSwiperNumericPagination,
	}
})

const results = new Swiper("#results .results-swiper-mobile", {
	slidesPerView: 1,
	spaceBetween: 30,
	
	speed: 1000,
	navigation: {
		nextEl: "#results .swiper-control.next",
		prevEl: "#results .swiper-control.prev",
	},
	pagination: {
		el: "#results .counter"
	},
	on: {
		init: updSwiperNumericPagination,
		slideChange: updSwiperNumericPagination,
		resize: updSwiperNumericPagination,
	}
})

const blog = new Swiper("#blog .swiper.blog-list", {
	slidesPerView: 1,
	speed: 700,
	spaceBetween: 34.08,
	navigation: {
		nextEl: "#blog .swiper-control.next",
		prevEl: "#blog .swiper-control.prev",
	},
	pagination: {
		el: "#blog .counter"
	},
	on: {
		init: updSwiperNumericPagination,
		slideChange: updSwiperNumericPagination,
		resize: updSwiperNumericPagination,
	}
})



function updSwiperNumericPagination(swiper) {
	if(!swiper.pagination.el) return;
  swiper.pagination.el.innerHTML = `
    <span class="current">${swiper.realIndex + 1}</span><svg width="40" height="1" viewBox="0 0 40 1" fill="none" xmlns="http://www.w3.org/2000/svg"><line y1="0.5" x2="40" y2="0.5" stroke="#0E0E0E"/></svg><span class="total">${swiper.slides.filter(slide=>!slide.classList.contains('hidden')).length}</span>
  `;
}


clickOn(
  ".services-slide:not(.swiper-slide-active), .services-slide:not(.swiper-slide-active) > *",
  (e) => {
    services.slideTo(
			services.slides.findIndex((slide) => slide === e.target.closest(".services-slide")),
		);
  }
);



