// import 'intl-tel-input/build/css/intlTelInput.min.css';


import "./utils";

import "./swiper"

import './popup'
import './accordeon'
import './events/click'
import './imask'
import './accordeon'
import './animations/cta_form'
import './animations/single-service'
import './events/intersection'
import './events/transitions'
import './events/resize'
import './events/mouse'
import './events/load'
import './video'
import './form'
import './accordeonFunctions'


import "../css/main.scss";
import './animations/home'
