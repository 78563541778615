export function hover(selector, callback){
	document.querySelectorAll(selector).forEach(el=>{
		el.addEventListener('mouseenter', callback)
	})
}
export function hoverout(selector, callback){
	document.querySelectorAll(selector).forEach(el=>{
		el.addEventListener('mouseleave', callback)
	})
}
export function move(selector, callback){
	let oldSelector = selector
	selector = `${oldSelector}, ${oldSelector} > *`
	document.querySelectorAll(selector).forEach(el=>{
		el.addEventListener('mouseenter', ()=>{
			document.addEventListener('mousemove', whenMove)
		})
	})
	document.querySelectorAll(selector).forEach(el=>{
		el.addEventListener('mouseleave', ()=>{
			document.removeEventListener('mousemove', whenMove)
		})
	})
	function whenMove(e){
		if(window.innerWidth > 991){
			callback(e, e.target.closest(oldSelector))
		}
	}
}