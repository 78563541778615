import gsap from "gsap";

import { whenOpen, whenClose } from "../events/popup";

whenOpen("cta", (e) => {
  window.innerWidth > 991 &&
    gsap.from(e.selector + " .window .cta-container > .text > *", {
      opacity: 0,
      y: 150,
      x: -50,
      duration: 0.9,
      ease: "back.out(1.7)",
      stagger: 0.1,
      skewY: -5,
    });
  window.innerWidth > 991 &&
    gsap.from(e.selector + " .window .cta-container > form > *", {
      opacity: 0,
      y: 150,
      x: 50,
      duration: 0.9,
      ease: "back.out(1.7)",
      stagger: 0.1,
      skewY: 5,
    });
  window.innerWidth < 991 &&
    gsap.from(e.selector + " .window .cta-container > * > *", {
      opacity: 0,
      y: 50,
      duration: 0.9,
      ease: "back.out(1.7)",
      stagger: 0.1,
    });
});
whenClose("cta", (e) => {
  window.innerWidth > 991 &&
    gsap.to(e.selector + " .window .cta-container > .text > *", {
      opacity: 0,
      y: -150,
      duration: 0.9,
      ease: "back.out(1.7)",
      stagger: 0.1,
			onComplete: () => {
				gsap.set(e.selector + " .window .cta-container > .text > *", { clearProps: "all" });
			}
    });
  window.innerWidth > 991 &&
    gsap.to(e.selector + " .window .cta-container > form > *", {
      opacity: 0,
      y: -150,
      duration: 0.9,
      ease: "back.out(1.7)",
      stagger: 0.1,
			onComplete: () => {
				gsap.set(e.selector + " .window .cta-container > form > *", { clearProps: "all" });
			}
    });
  window.innerWidth < 991 &&
    gsap.to(e.selector + " .window .cta-container > * > *", {
      opacity: 0,
      y: -50,
      duration: 0.9,
      ease: "back.out(1.7)",
      stagger: 0.1,
      onComplete: () => {
        gsap.set(e.selector + " .window .cta-container > * > *", { clearProps: "all" });
      },
    });
});
